// Here you can add other styles

@font-face {
  font-family: "Avertastd";
  src: url("../assets/fonts/AvertaStd-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Avertastd";
  src: url("../assets/fonts/AvertaStd-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Avertastd";
  src: url("../assets/fonts/AvertaStd-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Avertastd";
  src: url("../assets/fonts/AvertaStd-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Avertastd";
  src: url("../assets/fonts/AvertaStd-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-13;
  font-weight: $font-weight-normal;

  font-family: $font-family-Avertastd;
  color: $black;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  a {
    color: $theme-primary-color;
    // font-weight: 600;
    transition: all 0.3s ease;
    &:hover {
      color: $theme-dark-color;
    }
  }
  .btn {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  button {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .text-muted {
    color: $light-gray !important;
  }
  .text-gray {
    color: $light-gray-text !important;
  }
  .text-black {
    color: $black !important;
  }
  .text-theme {
    color: $theme-secondary !important;
  }
  .text-theme-2 {
    color: $theme-primary-color !important;
  }
  .text-link {
    color: $theme-primary-color;
    font-weight: normal;
    &:hover {
      color: $theme-dark-color;
      text-decoration: underline;
    }
  }
  .font-10 {
    font-size: $font-size-10 !important;
  }
  .font-11 {
    font-size: $font-size-11 !important;
  }
  .font-12 {
    font-size: $font-size-12 !important;
  }
  .font-13 {
    font-size: $font-size-13 !important;
  }
  .font-14 {
    font-size: $font-size-14 !important;
  }
  .font-15 {
    font-size: $font-size-15 !important;
  }
  .font-16 {
    font-size: $font-size-16 !important;
  }
  .font-17 {
    font-size: $font-size-17 !important;
  }
  .font-18 {
    font-size: $font-size-18 !important;
  }
  .font-20 {
    font-size: $font-size-20 !important;
  }
  .font-weight-medium {
    font-weight: $font-weight-medium !important;
  }
  .font-weight-bold {
    font-weight: $font-weight-bold !important;
  }
  .bg-success-outline {
    background-color: rgba(52, 195, 143, 0.18) !important;
  }
  .bg-warning-outline {
    background-color: rgba(241, 180, 76, 0.18) !important;
  }
  .bg-danger-outline {
    background-color: rgba(244, 106, 106, 0.18) !important;
  }
  .c-app {
    background: $light-grey-variation;
  }
  .mw-auto {
    min-width: 100% !important;
  }

  .feather-icon {
    width: 18px;
    height: 18px;
  }

  .feather-sm-icon {
    width: 14px;
    height: 14px;
  }

  .progress {
    height: 8px;
    .progress-bar {
      background-color: $theme-secondary;
    }
  }

  // bread crumb css
  .breadcrumb {
    position: relative;
    border: 0;
    box-shadow: 0 4px 20px 0 $black_variation2;
    margin: 20px;
    border-radius: 8px;
    .breadcrumb-item {
      a {
        color: $theme-primary-color;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
          color: $theme-dark-color;
          text-decoration: none;
        }
      }
      &.active {
        color: $grey_variation5;
      }
      & + .breadcrumb-item {
        &:before {
          color: $grey_variation5;
          // content: "";
        }
      }
    }
  }

  .sub-main-title {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px 40px;
    color: $black;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 80px;
      height: 3px;
      background: $theme-primary-color;
      left: 0px;
      bottom: -6px;
    }
  }
  // custom scroll
  .custom-scroll {
    transition: all 0.5s ease;
    &::-webkit-scrollbar {
      transition: all 0.5s ease;
      width: 7px;
      transition: all 0.5s ease;
      background-color: $white_variation2;
    }
    &::-webkit-scrollbar-thumb {
      transition: all 0.5s ease;
      border-radius: 8px;
      background: $grey_variation11;
    }
    ::-webkit-scrollbar-track {
      border-radius: 7px;
      transition: all 0.5s ease;
      background: $white_variation3;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background: $white_variation3;
      }
      &::-webkit-scrollbar {
        background-color: $white_variation2;
        transition: all 0.5s ease;
      }
      &::-webkit-scrollbar-thumb {
        background: $grey_variation11;
      }
    }
  }
  // card css
  .card {
    background-color: $white;
    border: 0;
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem $dark_blue;
    box-shadow: 0 -3px 31px 0 $dark_blue_variation1,
      0 6px 20px 0 $dark_blue_variation2;
    .card-header {
      padding: 12px 20px 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-bottom: 0;
      background-color: $white;
      border-bottom: 1px solid $white_variation4;
      color: $black;
      // border-bottom: unset;
      .btn {
        margin: 3px 0px 15px 0px;
        @media (max-width: 575px) {
          font-size: 14px;
          padding: 8px 8px;
        }
      }
    }
    .card-title {
      font-size: 18px;
      margin: 0 0 8px 0;
      font-weight: 600;
      color: $black;
    }
  }

  // table css
  .table {
    color: $black;
  }
  .table.custom-table {
    border-spacing: 0px 10px;
    border-collapse: separate;
    min-width: 760px;
    td,
    th {
      padding: 8px;
      vertical-align: middle;
      border: 0px;
      color: $black;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
    }
    .thead-color {
      th {
        background-color: rgba(43, 100, 165, 0.05);
      }
    }
    th {
      font-weight: 700;
      color: $black;
      background-color: rgba(43, 100, 165, 0.05);
      border-color: $white_variation7;
      border: 0px;
      white-space: nowrap;
      &.checkbox-col {
        .custom-checkbox {
          // width: 35px;
        }
      }
    }
    tr {
      color: $black;
      background-color: $white_variation8;

      &:hover {
        color: $black;
        background-color: $white_variation18;
      }
      &.no-hover {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      .name-title {
        font-size: 14px;
        font-weight: 600;
        color: $black;
      }

      td {
        &:first-child {
          border-top-left-radius: 0.375rem;
          border-bottom-left-radius: 0.375rem;
        }
        &:last-child {
          border-top-right-radius: 0.375rem;
          border-bottom-right-radius: 0.375rem;
        }
        &.text-field {
          max-width: 300px;
        }
        &.message-col {
          max-width: 500px;
        }
        &.tag-col {
          max-width: 225px;
        }
        &.checkbox-col {
          .custom-checkbox {
            // width: 35px;
          }
        }
      }
    }
  }

  // form css
  .custom-form-label {
    font-size: 14px;
    font-weight: 600;
    color: $black;
    line-height: normal;
    margin-bottom: 8px;
  }
  .custom-form-input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 400;
    color: $black;
    background-color: $white;
    border: 1px solid $white_variation10;
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    &:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid $theme-primary-color;
    }
  }
  textarea {
    &.custom-form-input {
      height: auto;
      min-height: 100px;
      resize: none;
    }
  }
  .custom-radio-input {
    .custom-control-label {
      display: inline-flex;
      align-items: center;
      color: $black;
      cursor: pointer;
      &::before {
        width: 22px;
        height: 22px;
        border: $white_variation10 solid 2px;

        left: -28px;
        top: 0px;
      }
      &::after {
        width: 28px;
        height: 28px;
        left: -31px;
        top: -3px;
      }
    }
    .custom-control-input {
      &:checked {
        & ~ .custom-control-label {
          &::before {
            color: $white;
            border-color: $theme-primary-color;
            background-color: $theme-primary-color;
          }
        }
      }
      &:focus {
        & ~ .custom-control-label {
          &::before {
            box-shadow: none;
            border-color: $white_variation10 !important;
          }
        }
      }

      &:not(:disabled):active {
        & ~ .custom-control-label {
          &::before {
            color: $white;
            border-color: $theme-primary-color;
            background-color: $theme-primary-color;
          }
        }
      }
    }
  }

  // checkbox
  .custom-checkbox {
    padding-left: 30px !important;
    .custom-control-label {
      font-weight: 600;
      color: $black;
      font-size: $font-size-14;
      cursor: pointer;
      &::before {
        width: 22px;
        height: 22px;
        border: $white_variation10 solid 2px;
        border-radius: 2px;
        left: -30px;
        top: 0px;
      }
      &::after {
        width: 24px;
        height: 26px;
        left: -31px;
        top: -1px;
      }
    }
    .custom-control-input {
      &:checked {
        & ~ .custom-control-label {
          &::before {
            color: $white;
          }
          &:after {
            background-image: url("../assets/images/icons/check.svg");
          }
        }
      }
      &:focus {
        & ~ .custom-control-label {
          &::before {
            box-shadow: none;
            // border-color: $theme-primary-color !important;
          }
        }
      }

      &:not(:disabled):active {
        & ~ .custom-control-label {
          &::before {
            color: $white;
          }
        }
      }
    }
  }

  .floating-input {
    border-radius: 0px;
    padding: 7px 5px;
    height: auto;
    vertical-align: middle;
    color: $black;
    margin-bottom: 40px;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    background-image: linear-gradient($theme-dark-color, $theme-dark-color),
      linear-gradient($grey_variation6, $grey_variation6);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    &:focus {
      outline: none;
      color: $black;
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s;
      ~ .floating-label {
        top: -18px;

        font-size: 12px;
      }
    }
    &:not(:placeholder-shown) {
      ~ .floating-label {
        top: -18px;

        font-size: 12px;
      }
    }
    &:disabled,
    &[readonly] {
      background-color: $white_variation2;
      opacity: 0.7;
      ~ .floating-label {
        opacity: 0.7;
      }
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $transparent;
    }
    &::-internal-autofill-selected {
      background-color: $black !important;
      border-bottom: 1px solid $grey_variation6;
      color: $black !important;
    }
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    margin-bottom: 0px;
    top: 4px;
    left: 5px;
    transition: 0.2s ease all;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    &.top-label {
      top: -18px;
      font-size: 12px;
      opacity: 1 !important;
    }
    &.pointers-event {
      pointer-events: auto;
    }
  }
  .form-group {
    position: relative;
  }
  .text-error {
    position: absolute;
    left: 0px;
    // bottom: -18px;
    font-size: 11px;
    color: $red;
    top: 35px;
  }
  .text-error-relative {
    font-size: 11px;
    color: $red;
  }
  .text-error-texarea {
    position: absolute;
    left: 0px;
    bottom: -18px;
    font-size: 11px;
    color: $red;
  }
  .selected-text {
    position: absolute;
    right: 0px;
    bottom: -26px;
    font-size: 14px;
    font-weight: 600;
    color: $theme-secondary;
  }

  // react select
  .select-form-group {
    .custom-form-label {
      display: none;
    }
  }
  .custom-reactselect {
    .custom-inner-reactselect__placeholder {
      font-size: 14px;
      font-weight: 600;
      color: $black;
      transition: "0.2s ease all";
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .custom-inner-reactselect__single-value {
      color: $black;
    }
    .custom-inner-reactselect__value-container {
      padding: 0px 5px 0px 0px;
    }
    .custom-inner-reactselect__control {
      min-height: 35px;
      font-size: 14px;
      font-weight: 400;
      border-radius: 0px;
      padding: 0px;
      height: auto;
      vertical-align: middle;
      color: $black;
      margin-bottom: 40px;
      border: 0;
      background-image: linear-gradient($theme-dark-color, $theme-dark-color),
        linear-gradient($grey_variation6, $grey_variation6);
      background-size: 0 2px, 100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(100% - 1px);
      background-color: rgba(0, 0, 0, 0);
      transition-duration: 0.3s;
      box-shadow: none;
      &.custom-inner-reactselect__control--is-focused {
        background-size: 100% 2px, 100% 1px;
        transition-duration: 0.3s;
      }
      &.custom-inner-reactselect__control--is-disabled {
        background-color: $white_variation2;
        opacity: 0.7;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        transition-duration: 0.3s;
      }
      &:hover {
        box-shadow: none;
        // border-color: $light_theme;
      }
    }
    .custom-inner-reactselect__indicator {
      padding: 7px;
    }
    .custom-inner-reactselect__option {
      color: $black;
    }
    .custom-inner-reactselect__menu {
      z-index: 2;
      color: $black;
      .custom-inner-reactselect__menu-notice--no-options {
        color: $black;
      }
    }
  }

  .custom-multiselect {
    --rmsc-main: #3271bc;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: rgba(50, 113, 188, 0.11);
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 4px; /* Radius */
    --rmsc-h: 35px; /* Height */

    .dropdown-container {
      // min-height: 35px;
      font-size: 14px;
      font-weight: 400;
      border-radius: 0px;
      padding: 0px;
      height: auto;
      vertical-align: middle;
      color: $black;
      margin-bottom: 40px;
      border: 0;
      background-image: linear-gradient($theme-dark-color, $theme-dark-color),
        linear-gradient($grey_variation6, $grey_variation6);
      background-size: 0 2px, 100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(100% - 1px);
      background-color: rgba(0, 0, 0, 0);
      transition-duration: 0.3s;
      box-shadow: none;
      &[aria-disabled="true"]{
        background-color: $gray-white;
      }
    }
    [aria-expanded="true"] {
      background-size: 100% 2px, 100% 1px;
      transition-duration: 0.3s;
    }
    .no-options {
      color: $black;
    }
    .dropdown-content {
      .search {
        input {
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $black;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $black;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $black;
          }
        }
      }
      .options {
        max-height: 215px;
      }
    }
  }

  .search-input {
    margin-bottom: 0px;
    margin-right: 10px;
    min-width: 200px;
    .floating-input {
      margin-bottom: 4px;
      padding: 11px 5px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $black;
        font-weight: 600;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $black;
        font-weight: 600;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $black;
        font-weight: 600;
      }
    }
  }

  // date picker
  .custom-datepicker {
    .react-datepicker-wrapper {
      display: block;
      font-family: $font-family-Avertastd;
      .floating-input {
        margin-bottom: 0px;
      }
    }
    .date-icon {
      position: absolute;
      top: 6px;
      color: $black;
      font-size: 15px;
      right: 5px;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
      .react-datepicker__triangle {
        border-bottom-color: $theme-secondary !important;
        &::before {
          top: 1px;
          border-bottom-color: $theme-secondary !important;
        }
      }
    }
    .react-datepicker-popper[data-placement^="top"] {
      .react-datepicker__triangle {
        &::before {
          border-top-color: $white !important;
        }
      }
    }

    .react-datepicker {
      font-family: $font-family-Avertastd;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
      font-size: 12px;
      border: 0px;
      .react-datepicker__navigation--previous {
        border-right-color: $white;
      }
      .react-datepicker__navigation--next {
        border-left-color: $white;
      }

      .react-datepicker__header {
        background-color: $theme-secondary;
        border-color: $theme-secondary;
        .react-datepicker__header__dropdown {
          margin-top: 4px;
          select {
            background: $white;
            border: 0px;
            border-radius: 4px;
            padding: 2px 6px;
          }
        }
      }
      .react-datepicker__current-month {
        color: $white;
        font-weight: 500;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        border-radius: 50%;
        background-color: $theme-secondary;
        color: $white;
      }

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: $white;
        }
      }
      .react-datepicker__day {
        &:hover {
          border-radius: 50%;
          background-color: $white_variation2;
        }
        &.react-datepicker__day--disabled {
          &:hover {
            background-color: $transparent;
          }
        }
      }
    }
  }

  // filter start
  .filter-form {
    .floating-input,
    .custom-reactselect .custom-inner-reactselect__control {
      margin-bottom: 12px;
    }
  }
  .filter-field-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .filter-field-tile {
      flex: 1 1 180px;
      max-width: 180px;
      &.user-tile {
        flex: 1 1 270px;
        max-width: 270px;
        @media (max-width: 376px) {
          flex: 1 1 100%;
          max-width: 100%;
        }
      }
      &.subject-tile {
        flex: 1 1 210px;
        max-width: 210px;
        @media (max-width: 376px) {
          flex: 1 1 100%;
          max-width: 100%;
        }
      }
      &.status-tile {
        flex: 1 1 160px;
        max-width: 160px;
        @media (max-width: 576px) {
          flex: 1 1 50%;
          max-width: 50%;
        }
        @media (max-width: 376px) {
          flex: 1 1 100%;
          max-width: 100%;
        }
      }
      @media (max-width: 576px) {
        flex: 1 1 50%;
        max-width: 50%;
      }
      @media (max-width: 376px) {
        flex: 1 1 100%;
        max-width: 100%;
      }
      padding: 0px 7.5px;
      margin-bottom: 20px;
      &.buttons-tile {
        flex: 1 1 100px;
        max-width: 100px;
      }
      .form-group {
        margin-bottom: 0px;
      }
    }
    .custom-inner-reactselect__menu-list {
      max-height: 142px;
    }
    .dropdown-container {
      margin-bottom: 12px;
    }
  }

  .date-filter-tile {
    flex: 1 1 280px !important;
    max-width: 280px !important;
    .DateRangePickerInput__withBorder {
      border-radius: 0px;
      border: 0px;
      border-bottom: 1px solid $grey_variation6;
    }
    .DateInput_input__focused {
      border-bottom: 1px solid $theme-dark-color;
    }
    .CalendarDay__default {
      color: $black;
    }
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      color: $white_variation2;
    }
    .DayPicker_weekHeader {
      color: $black;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: $theme-dark-color;
      border: 1px double $theme-dark-color;
      color: $white;
    }
    .CalendarDay__selected_span {
      background: $theme-light-green-color;
      border: 1px double $theme-dark-color;
      color: $white;
    }
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background: $theme-light-green-color;
      border: 1px double $theme-dark-color;
      color: $black-mid;
    }
    .CalendarMonth_caption {
      padding-top: 16px;

      select {
        font-size: 14px;
        color: $black;
        border: 1px solid $white_variation15;
        background: $white;
        padding: 6px 8px;
      }
    }
    .DateInput {
      width: 105px;
      @media (max-width: 575px) {
        width: 90px;
      }
    }
    .DateRangePickerInput_arrow {
      margin-right: 9px;
      .DateRangePickerInput_arrow_svg {
        fill: $grey_variation13;
        height: 18px;
        width: 18px;
      }
    }
    .DateRangePickerInput_calendarIcon {
      padding: 8px 5px;
      margin: 0px;
      .DateRangePickerInput_calendarIcon_svg {
        fill: $black;
      }
    }
    .DateInput_input {
      padding: 7px 5px;
      color: $black;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      min-height: 35px;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $black;
        font-weight: 500;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $black;
        font-weight: 500;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $black;
        font-weight: 500;
      }
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right: 33px solid $theme-dark-color;
    }
  }

  // btn css
  .btn-theme {
    color: $white;
    padding: 8px 16px;
    min-width: 90px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    transition: all 0.25s ease;
    //    box-shadow: 0 6px 2rem 0px rgb(37 189 133 / 26%);
    background: $theme-secondary;
    border: 1px solid $theme-secondary;

    border-radius: 4px;
    &:hover {
      color: $white;
      background: $theme-secondary-hover;
      border-color: $theme-secondary-hover;
      box-shadow: -0px 4px 25px -8px $theme-secondary;
    }
  }
  .btn-theme-2 {
    background: $theme-primary-color;
    border: 1px solid $theme-primary-color;
    color: $white;
    padding: 8px 16px;
    min-width: 90px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    transition: all 0.25s ease;
    border-radius: 4px;
    &:hover {
      color: $white;
      background: $theme_color;
      border-color: $theme_color;
      box-shadow: 0px 4px 25px -8px $theme-primary-color;
    }
  }
  .btn-theme-secondary {
    color: $white;
    background-color: $secondary;
    border: 1px solid $secondary;
    padding: 8px 16px;
    min-width: 90px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

    transition: all 0.25s ease;
    border-radius: 4px;
    &:hover {
      color: $white;
      background-color: $theme-secondary-gray;
      border-color: $theme-secondary-gray;
      box-shadow: -0px 4px 25px -8px $secondary;
    }
  }

  .filter-btn {
    height: 35px;
    width: 35px;
    min-width: auto;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    padding: 0px;
    font-size: 16px;
    line-height: normal;
    justify-content: center;
    // border: 0px;
    transition: all 0.3s ease;
    font-family: $font-family-Avertastd;
    &.search-btn {
      color: $white;
      background-color: $theme-primary-color;
      border-color: $theme-primary-color;
      &:hover {
        color: $white;
        background-color: $theme-primary-color;
        border-color: $theme-primary-color;
        box-shadow: -1px 3px 12px 0px $grey_variation1;
      }
    }
    &.reset-btn {
      margin-left: 10px;
      color: $white;
      background-color: $grey_variation2;
      border-color: $grey_variation2;
      &:hover {
        color: $white;
        background-color: $grey_variation4;
        border-color: $grey_variation4;
        box-shadow: -1px 3px 12px 0px $grey_variation1;
      }
    }
  }

  .label-height {
    height: 19px;
    margin-bottom: 4px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .tags {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $dark_blue_variation5;
    color: $black;
    border-radius: 8px;
    padding: 2px 12px;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    min-width: 70px;
  }
  // status css
  .status-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $dark_green;
    color: $black;
    border-radius: 5px;
    padding: 5px 12px;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    min-width: 70px;
    transition: all 0.25s ease;
    &.status-active {
      color: $black;
      background-color: $theme_green_variation3;
    }
    &.status-inactive {
      color: $black;
      background-color: $theme_danger;
    }
    &.status-pending {
      color: $black;
      background-color: $theme_warning;
    }
    &.status-info {
      background-color: rgba(80, 165, 241, 0.41);
      color: $black;
    }
    &.status-open {
      background-color: rgba(54, 153, 255, 0.12);
      color: $black;
    }
    &.status-secondary {
      background-color: rgba(13, 14, 14, 0.12);
      color: $black;
    }
  }

  .action-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .action-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $black;
      border-radius: 5px;
      font-size: 18px;
      margin: 0px 8px;
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color: $theme-secondary;
      }
      .feather-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

// nodata start
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 30px 0px;
  position: relative;
  min-height: 100px;
  .no-data-icon {
    font-size: 30px;
  }
}
// nodata end

// sweetalert start
.swal2-container {
  .swal2-title {
    font-size: 18px;
    font-weight: 700;
    color: $black;
    font-family: $font-family-Avertastd;
  }
  .swal2-content {
    color: $black;
    font-size: 14px;
    font-family: $font-family-Avertastd;
  }
  button.swal2-confirm {
    background: $theme-secondary !important;
    border: 1px solid $theme-secondary !important;
    color: $white;
    padding: 8px 20px;
    min-width: 90px;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    transition: all 0.25s ease;
    border-radius: 4px;
    box-shadow: none;
  }
  button.swal2-cancel {
    margin-left: 10px;
    color: $white;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    background-color: $grey_variation8 !important;
    border: 1px solid $grey_variation8;
    padding: 8px 20px;
    min-width: 90px;
    box-shadow: none;
  }
  .swal2-popup {
    max-width: 350px;
  }
  .swal2-icon {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 3em;
    height: 3em;
    &::before {
      font-size: 2.5em;
    }
    .swal2-icon-content {
      font-size: 2.5em;
    }
  }
  .swal2-actions {
    margin-top: 15px;
  }
  .swal2-close {
    color: $black;
    font-size: 32px;
    &:hover {
      opacity: 0.8;
    }
  }
}
// sweetalert end

// details pages css
.info-list {
  display: flex;
  margin-bottom: 8px;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0px 10px;
  @media (max-width: 1100px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &.flex-50 {
    flex: 0 0 50%;
    max-width: 50%;
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &.flex-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .icon-wrap {
    flex: 0 0 40px;
    max-width: 40px;
    color: $theme-primary-color;
    img {
      width: 24px;
    }
  }
  .description-wrap {
    flex: 1;
  }
  .text-heading {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  .text-value {
    font-size: 14px;
    font-weight: 400;
    color: $black;
    word-wrap: break-word;
    word-break: break-word;
  }
}

//modal css
.modal {
  .modal-content {
    background-color: $white;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 0.2rem;
  }
  .modal-header {
    // background-color: $theme-secondary !important;
    padding: 1rem 1rem;
    border-bottom: 1px solid $white_variation11;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    .modal-title {
      font-weight: 600;
      color: $black;
      letter-spacing: 0.1px;
      font-size: 18px;
    }
  }
  .modal-close {
    background: transparent;
    box-shadow: none;
    border: 0px;
    color: $grey_variation6;
    padding: 4px 5px;
    position: absolute;
    top: 16px;
    right: 13px;
    border-radius: 3px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background: rgba(187, 187, 187, 0.32);
      color: $grey_variation5;
    }
    .feather-icon {
      width: 22px;
      height: 22px;
    }
  }
  .info-modal {
    .modal-body {
      padding: 22px 20px;
      p {
        font-size: 16px;
      }
    }
    .modal-close {
      top: 1px;
      right: 0px;
      z-index: 1;
      &:hover {
        background: rgba(187, 187, 187, 0);
        color: $theme-secondary;
      }
    }
  }
}

// multiple select css
.grid-select-list {
  list-style: none;
  padding: 0px;
  max-height: 350px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .users-list {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 8px 8px;
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    &.full-width {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-checkbox {
      background: $white;
      box-shadow: -1px 4px 6px 3px $black_variation1;
      padding: 10px 4px 10px 40px;
      padding-left: 40px !important;
      &:hover {
        background: $white_variation8;
        cursor: pointer;
        box-shadow: 1px 1px 3px 3px $black_variation2;
      }
      &.selected {
        background: $white_variation13;
        .custom-control-label {
          color: $theme-primary-color;
        }
      }

      .custom-control-label {
        display: flex;
        font-family: $font-family-Avertastd;
        // &:after,
        // &:before {
        //   left: -45px;
        // }
      }
    }
  }
}

.subject-list-wrap {
  background: $white_variation3;
  display: flex;
  flex-flow: column;
  height: calc(100% - 20px);
  padding: 13px 20px;
  .custom-checkbox {
    margin-bottom: 18px;
  }
}

.subject-tag {
  ul {
    padding: 6px 5px;
    height: auto;
    border-bottom: 1px solid $grey_variation6;
    vertical-align: middle;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    background: $white_variation2;
  }
  &.disable {
    opacity: 0.7;
  }
}

// view list css
.view-user-list {
  list-style: none;
  padding: 0px;
  max-height: 350px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
  li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0px 5px;
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .users-description {
      padding-bottom: 10px;
      padding: 8px 20px;
      background: $white_variation8;
      margin-bottom: 4px;
      font-family: $font-family-Avertastd;
      color: $black;
      &:hover {
        background: $white_variation15;
        cursor: pointer;
      }
    }
  }
}

// assignment list

.assignment-wrap {
  .assignment-subject-name {
    font-size: 16px;
    font-weight: 600;
    color: $black;
  }
  .assignment-list {
    margin-bottom: 10px;
    padding-left: 10px;
    .assignment-list-item {
      font-size: 14px;
      color: $black;
      transition: all 0.3s;
      border-bottom: 1px solid $gray-white;
      padding: 6px 3px;
      display: flex;
      align-items: center;

      &:last-child {
        border-bottom: 0px;
      }
      .lesson-title {
        flex: 1;
      }
      .list-icon {
        margin-right: 15px;
        color: $black;
        &.icon-circle {
          background: $grey_variation15;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: $white;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &.completed {
            background: $success;
          }
          .feather-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.assignment-statistics {
  .progress {
    height: 6px;
    border-radius: 8px;
    font-size: 10px;
    background-color: $white_variation4;
  }
  .statistics-text {
    font-size: 14px;
    text-align: left;
    color: $black;
    margin-top: 6px;
    font-weight: 600;
  }
}

// tootlip css
.tippy-box {
  background-color: $light-black;
  font-size: 13px;
  font-family: $font-family-Avertastd;
  .tippy-content {
    max-width: 250px;
    padding: 4px 10px;
    color: $white;
    text-align: center;
    background-color: $light-black;
    border-radius: 4px;
  }
}

.assignment-tag {
  margin-bottom: 0px;
  text-align: left;
  &.assignment-label {
    position: absolute;
    right: 20px;
    top: -26px;
    color: $black;
    @media (max-width: 767px) {
      position: relative;
      right: unset;
      top: unset;
      margin-bottom: 12px;
    }
  }
  li {
    position: relative;
    padding-left: 20px;
    &:after {
      content: "";
      position: absolute;
      left: 0px;
      top: 4px;
      background: $white_variation2;
      width: 8px;
      height: 8px;
      border: 0px;
      border-radius: 2px;
    }
    &.complete {
      &:after {
        background: $success;
      }
    }
    &.complete-after {
      &:after {
        background: $danger;
      }
    }
    &.incomplete {
      &:after {
        background: $white_variation2;
      }
    }
    &.not-attempt {
      &:after {
        background: $warning;
      }
    }
  }
}

// animations
.spin-an {
  animation: spin 2s linear infinite;
}

.tada-an {
  animation: tada 1.5s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scaleX(1) rotate(10deg);
    transform: scaleX(1) rotate(10deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scaleX(1) rotate(-10deg);
    transform: scaleX(1) rotate(-10deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scaleX(1) rotate(10deg);
    transform: scaleX(1) rotate(10deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scaleX(1) rotate(-10deg);
    transform: scaleX(1) rotate(-10deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

// dropzone css

.dropzone-preview {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 175px;
  min-height: 175px;
  max-width: 100%;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  border: 2px dashed $white_variation17;
  overflow: hidden;
  color: $dark-gray-mid;
  font-size: 14px;
  .feather-icon {
    color: $grey_variation10;
    width: 50px;
    height: 50px;
  }
  &:focus {
    outline: none;
  }
}

.import-student-heading {
  font-weight: bold;
  font-size: 20px;
  color: $theme-secondary;
}
.import-student-link {
  color: $theme-secondary;
}
.import-download-sample {
  color: $theme-secondary;
}

// custom tab start
.custom-tab-wrap {
  .custom-nav {
    margin-bottom: 8px;
    border-bottom: 2px solid $theme-secondary;
    .nav-item {
      .nav-link {
        padding: 8px 15px;
        border-radius: 0;
        color: $black;
        text-align: center;
        font-size: $font-size-14;
        background: $white;
        border-left: 1px solid $white_variation16;
        transition: all 0.3s ease;
        border-radius: 4px 4px 0px 0px;
        // top: 1px;
        position: relative;
        box-shadow: 0 -3px 31px 0 rgba(18, 38, 63, 0.05),
          0 6px 20px 0 rgba(18, 38, 63, 0.02);

        @media (max-width: 767px) {
          padding: 8px 12px;
        }
        @media (max-width: 575px) {
          padding: 8px 12px;
          margin-bottom: 6px;
          border-radius: 4px;
        }
        &:hover {
          color: $theme-secondary;
        }
        &.active {
          background: $theme-secondary;
          color: $white;
        }
      }
      &:not(:last-child) .nav-link {
        margin-right: 4px;
      }
    }
  }

  .card {
    border-radius: 0px 0px 8px 8px;
  }
}

.plan-detail {
  .plan-title {
    font-size: 22px;
    font-weight: 700;
    color: $black;
    margin-bottom: 0px;
  }
  .plan-price {
    font-weight: 700;
    font-size: 25px;
    color: $black;
    text-align: right;
    margin-bottom: 0px;
    @media (max-width: 575px) {
      text-align: left;
    }

    small {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }
  }
  &.current-plan-details {
    .plan-title {
      font-size: 20px;
    }
    .plan-price {
      font-size: 20px;
    }
  }
}

.purchase-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 89.4%, 0.42);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .purchase-wrap {
    padding: 0px 20px;
  }
  .purchase-icon {
    color: $theme-secondary;
    margin-bottom: 16px;
    .feather-icon {
      width: 35px;
      height: 45px;
    }
  }
  .purchase-title {
    font-size: 28px;
    font-weight: 600;
    color: $theme-secondary;
    margin-bottom: 16px;
  }
  .purchase-text {
    font-size: 16px;
    line-height: 20px;
    margin: 0px auto 18px;
    color: $black;
  }
}
.dummy-content {
  filter: blur(5px);
}

.modal-dialog {
  @media (min-width: 767px) {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

.support-modal-view {
  .info-list {
    &.flex-50 {
      @media (max-width: 991px) {
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (max-width: 767px) {
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
.personal-info-card {
  .col-lg-3 {
    @media (min-width: 992px) and (max-width: 1199px) {
      -webkit-flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}

.no-data-content-black {
  color: #000000;
}

.new-filter {
  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
  .filter-field-tile.subject-tile {
    padding-left: 0px;
    width: 210px;
  }
  .custom-reactselect {
    .custom-inner-reactselect__control {
      margin-bottom: 4px;
      @media (min-width: 575px) {
        margin-bottom: 8px;
      }
    }
  }
}

.table.table-align-top {
  tr {
    td {
      @media (max-width: 767px) {
        vertical-align: top;
      }
    }
  }
}


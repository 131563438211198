// Variable overrides
$font-family-Avertastd: "Avertastd", sans-serif;
$bg-gray: #f5f8f1;
$black: #000;
$transparent: transparent;
$white: #fff;
$primary: #1ddf22;
$secondary: #74788d;
$success: #34c38f;
$info: #3699ff;
$warning: #f1b44c;
$danger: #f64e60;
$light: #f8f9fa;
$dark: #343a40;
$light-red: #ff6565;
$red: #e83131;
$yellow: #ffc700;
$light-yellow: #fff8dd;
$light-green: #b7efdb;
$theme-primary-color: #33d197;
$theme-dark-color: #1ddf22;
$theme-light-green-color: #90da90;
$theme-secondary: #0070bc;
$theme-secondary-hover: #01558d;
$theme-secondary-gray: #636678;
$light-black: #333;
$black-mid: #4d4f5c;
$gray-white: #eceaea;
$light-white: #f5f6fa;
$light-gray: #a6a7ae;
$light-gray-text: #9f9fac;
$light-gray-mid: #a5a4bf;
$border-gray: #e8e9ec;
$light-white-opaque: #fafafa;
$border-light-gray: #f1f1f3;
$light-gray-opaque: #ebebf2;
$dark-gray: #838296;
$dark-gray-mid: #6c757d;
$navy-blue: #1a3353;
$navy-blue_variation1: rgba(0, 112, 199, 0.17);
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-25: 25px;
$font-size-28: 28px;
$white_variation1: #e3ece9;
$white_variation2: #ddd;
$white_variation3: #eaeaea;
$white_variation4: #e6e2e2;
$white_variation5: #fdfdfd;
$white_variation6: #d9dcdb;
$white_variation7: #f5f5f5;
$white_variation8: #f7f7f7;
$white_variation10: #ced4da;
$grey_variation1: #5858584f;
$grey_variation2: #acb1af;
$grey_variation3: #bbbbbb52;
$grey_variation4: #969e9a;
$grey_variation5: #626262;
$grey_variation6: #909090;
$light_purple: #8c91b6;
$light_purple_variation1: #556ee6;
$dark_green: #20c10c42;
$green: #2abf18;
$green_variation1: #00794d;
$green_variation2: rgba(29, 223, 34, 0.45);
$theme_color: #34c38f;
$theme_color_variation1: #64b99a;
$theme_color_variation2: #86dfbd;
$light_danger: #f46a6a;
$grey_variation7: #a29f9f;
$grey_variation8: #8b8d94;
$grey_variation9: #4a4a4a;
$white_variation11: #e5e8eb;
$black_variation1: #00000017;
$black_variation2: #5a5a5a21;
$white_variation13: #def8ee;
$white_variation15: #eae7e7;
$white: #fff;
$white_variation16: #f2edff;
$light-blue: #d6dcea;
$blue_variation1: #0070bb;
$light-grey-variation: #e9ecef;
$light-blue-variation: #e8f2f9;
$white_variation17: #b7b7b7;
$grey_variation10: #c7c7c7;
$grey_variation11: #bbbaba;
$grey_variation12: #495057;
$grey_variation13: #949ba2;
$grey_variation14: #212529;
$grey_variation15: #b5b5b5;
$black_variation2: rgba(0, 0, 0, 0.05);
$dark_blue: rgba(18, 38, 63, 0.03);
$dark_blue_variation1: rgba(18, 38, 63, 0.05);
$dark_blue_variation2: rgba(18, 38, 63, 0.02);
$theme_green: rgba(24, 161, 111, 0.11);
$white_variation18: rgb(241, 241, 241);
$theme_green_variation1: rgba(37, 189, 133, 0.26);
$dark_blue_variation3: rgba(0, 122, 188, 0.45);
$theme_green_variation2: rgba(37, 189, 133, 0.45);
$dark_blue_variation4: rgba(116, 120, 141, 0.4);
$dark_blue_variation5: rgba(0, 112, 188, 0.19);
$theme_green_variation3: rgba(52, 195, 143, 0.18);
$theme_danger: rgba(244, 106, 106, 0.18);
$theme_warning: rgba(241, 180, 76, 0.18);
$grey_variation16: rgb(144, 144, 144);
$grey_variation18: #a2a2a2;
$white_variation19: #f1f1f1;
$white_variation20: #e4eef5;
$white_variation21: #d4e7f6;
$grey_variation19: #495057;
$grey_variation21: #b1b1b1;
$grey_variation22: #f3f3f7;
$grey_variation23: #a6a9a8;
$grey_variation24: #74788d;
$grey_variation25: #495057;
$theme-blue-color: #0071bd;

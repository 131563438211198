body {
  // header css
  .c-header {
    border-bottom: 0px;
    min-height: 65px;
    background: $white;
    transition: all 0.45s ease 0s;
    // border-radius: 15px;
    // margin: 15px 15px 0;
    box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
    .brand-logo {
      padding: 2px 20px;
      img {
        width: auto;
        height: 50px;
        @media (max-width: 359px) {
          height: 44px;
        }
      }
    }
    // .c-header-toggler-icon {
    //   background-image: url("../assets/images/icons/menu.svg");
    // }
    .dropdown-menu {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      font-size: $font-size-13;
      text-align: left;
      padding: 6px 4px;
      list-style: none;
      background-color: $white;
      border: 0px;
      border-radius: 0px;
      animation-name: DropDownSlide;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      left: 65px !important;
      top: 8px !important;
      &.show {
        &:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          right: 73px;
          border-width: 9px;
          border-style: solid;
          border-color: $transparent $transparent $white $transparent;
        }
      }
      .dropdown-divider {
        margin: 2px auto;
        width: 80%;
        border-top: 1px solid $border-light-gray;
      }
      .dropdown-header {
        color: $black;
        font-weight: 700;
        font-size: 14px;
      }
      .dropdown-item {
        position: relative;
        padding: 10px 20px;
        color: $black;
        font-size: $font-size-13;
        white-space: nowrap;
        @include align-center;
        text-align: left;
        border: 0;
        transition: all 0.3s ease;
        border-radius: 2px;
        &:hover {
          color: $theme-primary-color;
          text-decoration: none;
          background-color: $light-white;
        }
        &:active,
        &.active {
          color: $white;
          background-color: $theme-primary-color;
        }
        .feather-icon {
          width: 18px;
          height: 18px;
          font-size: $font-size-18;
        }
      }
    }

    .c-header-nav {
      .c-header-nav-item {
        margin: 0px 5px;
        &.show {
          .c-header-nav-link {
            color: $black;
            background: $gray-white;
            border-radius: 50%;
          }
        }
      }

      .c-header-nav-link {
        color: $black;
        &:hover {
          color: $black;
          background: $gray-white;
          // background: rgba(115, 115, 115, 0.12);
          border-radius: 50%;
        }
        &:hover {
          svg {
            animation: tada 1.5s ease infinite;
          }
        }
      }
      .feather-icon {
        width: 20px;
        height: 20px;
        font-size: $font-size-20;
      }
      .header-search-bar {
        position: relative;
        display: flex;
        width: 400px;
        height: 100%;
        align-items: center;
        @media (max-width: 905px) {
          width: 263px;
        }

        .form-control {
          border: none;
          border-radius: 25px;
          height: 37px;
          padding-left: 40px;
          color: $black;
          background: $gray-white;
          align-items: center;

          &:focus {
            box-shadow: none;
            outline: none;
            border: none;
          }
        }
        .search-icon {
          position: absolute;
          left: 13px;
          top: 23px;
          width: 20px;
          height: 20px;
          font-size: 20px;
          color: $light-gray-mid;
          @media (max-width: 767px) {
            top: 18px;
          }
        }
      }
    }
    .notification-dropdown {
      .dropdown-menu {
        width: 500px;
        padding: 0px;
        left: -280px !important;
        top: 8px !important;
        @media (max-width: 767px) {
          left: -400px !important;
        }
        @media (max-width: 575px) {
          width: 310px;
          padding: 0px;
          left: -240px !important;
          top: 8px !important;
        }
        @media (max-width: 359px) {
          width: 300px;
          left: -255px !important;
        }
      }
      .dropdown-header {
        background: $theme-secondary;
        color: $white;
        font-weight: 700;
        font-size: 14px;
        padding: 15px 20px;
      }
    }
    .notifications-badge {
      position: absolute;
      right: 3px;
      top: -4px;
      font-size: 10px;
      line-height: normal;
      display: flex;
      width: 18px;
      height: 18px;
      color: $white;
      background: $light-red;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
    .dropdown-menu .dropdown-item.notification-list {
      border-bottom: 1px solid $white_variation11;
      transition: all 0.3s ease-in-out;
      color: $black;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $white_variation8;
      }
      &.unread {
        background-color: $light-blue-variation;
      }
      .notification-icon {
        height: 2rem;
        width: 2rem;
        margin-right: 12px;
        align-items: center;
        background-color: #556ee6;
        color: $white;
        display: flex;
        font-weight: 500;
        justify-content: center;
        border-radius: 50%;
        img {
          width: 16px;
          filter: brightness(0) invert(1);
        }
        &.color-1 {
          background-color: #6ad8a0;
        }
        &.color-2 {
          background-color: #7e70d6;
        }
        &.color-3 {
          background-color: #bb184b;
        }
        &.color-4 {
          background-color: #6fd4de;
        }
        &.color-5 {
          background-color: #f0ca21;
        }
        &.color-6 {
          background-color: #a421f0;
        }
      }
      .notification-message {
        flex: 1 1;
        font-size: 12px;
        line-height: normal;
        white-space: normal;
      }
      .notification-head {
        font-size: 15px;
        line-height: normal;
        margin-bottom: 4px;
        color: $black;
        font-weight: 600;
      }
      .notification-subtext {
        font-size: 14px;
      }
      .notification-time {
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        line-height: normal;
      }
    }
    .notification-wrapper {
      max-height: 400px;
      overflow-y: auto;
    }
    .user-dropdown {
      .user-dropdown-toggle {
        @include align-center;
        font-size: $font-size-13;
        position: relative;
        &:hover {
          color: $white;
          background: $transparent;
          border-radius: 0%;
        }
        .c-avatar {
          border-radius: 4px;
          width: auto;
          height: auto;
          color: $black;
          .c-avatar-img {
            border-radius: 4px;
            width: 40px;
            height: 40px;
            margin-right: 6px;
          }
          .c-avatar-icon {
            background: $theme-secondary;
            width: 40px;
            height: 40px;
            padding: 9px;
            border-radius: 5px;
            color: $white;
            margin-right: 5px;
          }
        }
        .c-avatar-info {
          padding-right: 16px;
          position: relative;
          font-size: $font-size-15;
          color: $black;
          font-weight: 700;
          font-size: 14px;
          text-align: right;
          div {
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          @media (max-width: 575px) {
            display: none;
          }
        }
        .c-avatar-sub-info {
          color: $black;
          font-weight: 400;
          font-size: 14px;
        }
        &:hover {
          svg {
            animation: none;
          }
        }
      }
      .dropdown-menu {
        right: 5px !important;
        left: unset !important;
        top: 4px !important;
        width: 200px;
      }
    }
  }

  // sidebar css
  .c-sidebar {
    box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 1px 11px 13px 0px rgba(0, 0, 0, 0.21);
    background: $white;
    width: 220px;
    &.c-sidebar-fixed {
      @media (min-width: 992px) {
        top: 65px;
      }
    }

    .c-sidebar-brand {
      color: $white;
      background: $transparent;
      border-bottom: 1px solid rgba(146, 146, 146, 0.18);
      padding: 5px 20px;
      &:hover {
        text-decoration: none;
      }
    }
    .custom-minimizer {
      position: absolute;
      top: 14px;
      right: 0px;
      width: 56px;
      height: 25px;
      cursor: pointer;
      @include center-div;
      background: $transparent;
      &:before {
        height: 25px;
        background-image: url("../assets/images/icons/menu-bar-line.svg");
        background-size: 25px;
        width: 25px;
        transform: none !important;
      }
      &:hover {
        background: $transparent;
        &:before {
          background-image: url("../assets/images/icons/menu-bar-line-hover.svg");
        }
      }
      &:focus {
        outline: none;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }

    &.c-sidebar-minimized {
      .c-sidebar-brand {
        .logo {
          display: none;
        }
      }
      .c-sidebar-nav {
        margin: 0px 8px 0px 8px;
        .c-sidebar-nav-item {
          .c-sidebar-nav-link {
            padding: 10px 8px;
            border-radius: 4px;
            border-right: 0px;
            &:hover {
              @media (min-width: 992px) {
                width: 250px;
              }
            }
          }
          &:hover {
            background: transparent;
            .c-sidebar-nav-link {
              color: $theme-secondary;
              background: $gray-white;
              .c-sidebar-nav-icon {
                color: $theme-secondary;
              }
            }
          }
        }
      }
    }

    .c-sidebar-nav {
      margin: 0px 8px 0px 0px;
      padding-top: 20px;
      @media (max-width: 991px) {
        overflow-y: auto !important;
      }
    }
    .c-sidebar-nav-item {
      padding: 6px 0px;
      .c-sidebar-nav-link {
        display: flex;
        border-radius: 0px 5px 5px 0px;
        align-items: center;
        font-size: 14px;
        transition: all 0.5s ease;
        padding: 10px 15px;
        cursor: pointer;
        color: $black;
        .c-sidebar-nav-icon {
          width: auto;
          font-size: 20px;
          color: $black;
          width: 24px;
          height: 24px;
          // viewbox: 0 0 24px 24px;
          fill: none;
          stroke: currentColor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
        &:hover {
          color: $theme-secondary;
          background: $gray-white;
          transform: translateX(5px);
          .c-sidebar-nav-icon {
            color: $theme-secondary;
          }
        }
        &.c-active {
          color: $white;
          background: linear-gradient(to right, #0072bf 0%, #05578e 100%);
          .c-sidebar-nav-icon {
            color: $white;
          }
        }
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          background: url("../assets/images/icons/lock.svg") $white_variation8
            center right no-repeat;
          background-position: 94% 11px;
          background-size: 18px;
          opacity: 0.8;
        }
      }
    }

    &.c-sidebar-minimized {
      .c-sidebar-brand {
        .logo-title {
          display: none;
        }
      }
    }
  }

  .c-main {
    padding-top: 65px;
    .container-fluid {
      padding: 0 16px;
    }
  }

  //   breadcrumb
  .breadcrumb {
    padding: 0px;
    border-bottom: 0px;
    box-shadow: none;
    .breadcrumb-item {
      align-items: center;
      position: relative;
      &:first-child {
        padding-left: 23px;
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          top: 0px;
          background-image: url("../assets/images/icons/home-black.svg");
          background-size: 17px;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
        }
        &:hover {
          &::before {
            background-image: url("../assets/images/icons/home.svg");
          }
        }
      }
      a {
        font-size: $font-size-13;
        color: $black;
        &:hover {
          text-decoration: none;
          color: $theme-secondary;
        }
      }
      & + .breadcrumb-item {
        &:before {
          color: $black;
        }
      }
      &.active {
        // color: $theme-secondary-gray;
        color: $theme-secondary;
        font-size: $font-size-13;
      }
    }
  }
}

@keyframes DropDownSlide {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 220px;
  }
}

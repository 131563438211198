@import "../../../scss/variables";
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;

  font-family: $font-family-Avertastd;
}
.auth-content {
  padding: 0;
  box-shadow: 0 5px 30px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  background: $white;
  min-height: 100vh;
  display: flex;
  width: 100%;
  // border-radius: 24px;
}
.auth-card {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
  border-radius: 0 24px 24px 0px;
  background: $white;
  padding: 55px 55px 55px 55px;
  justify-content: center;
  @media (max-width: 991px) {
    padding: 35px;
  }
  @media (max-width: 767px) {
    background: $white_variation21;
    border-radius: 0px;
  }
}
.login-title {
  font-size: 30px;
  color: $theme-secondary;
  font-weight: 700;
  font-family: $font-family-Avertastd;
  margin-bottom: 8px;
}
.login-sub-text {
  font-size: 15px;
  font-weight: 500;
  max-width: 400px;
  margin: 0 auto 16px;
}
.banner-img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    max-width: 100%;
    height: auto;
  }
}
.banner-text {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 40px;
  margin-top: 5%;
  font-family: $font-family-Avertastd;
  h2 {
    font-size: 42px;
    font-weight: 700;
    color: $blue_variation1;
    span {
      color: $primary;
    }
  }
  p {
    font-size: 22px;
    font-weight: 400;
    color: $black;
    margin-left: 10px;
  }
}
.auth-form-div {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  .input-icon {
    position: absolute;
    right: 5px;
    top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 18px;
    color: $grey_variation23;
  }
}
.btn-submit {
  background: $primary;
  border: 1px solid $primary;
  color: $white;
  padding: 12px 25px;
  min-width: 90px;
  margin: 10px 0px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  box-shadow: -1px 2px 12px 2px $green_variation2;
  transition: all 0.25s ease;
  //    box-shadow: 0 6px 2rem 0px rgb(37 189 133 / 26%);
  position: relative;
  border-radius: 22px;
  .feather-icon {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    right: 10px;
    top: 0px;
    width: 20px;
    opacity: 0;
    transition: all 0.25s ease;
  }
  &:hover {
    color: $white;
    background: $theme-dark-color;
    border-color: $theme-dark-color;
    .feather-icon {
      opacity: 1;
    }
  }
}
.right-section {
  background-color: $navy-blue_variation1;
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    display: none;
  }
}

// end auth wrapper
